.container {
  padding: 217px 0 0 120px;
}
.title {
  font-size: 28px;
  line-height: 40px;
  color: #333;
  margin-bottom: 16px;
}
.infoBox {
  margin-top: 8px;

}
.msg {
  color: #696F7F;
  font-size: 14px;
  line-height: 20px;
}
.imgBox {
  margin-top: 36px;
}


@media (max-width: 960px) {
  .container {
    padding: 0 15px;
  }
  .title {
    font-size: 21px;
    line-height: 10px;
    margin-bottom: 12px;
  }
  .imgBox {
    margin-top: 16px;
    margin-bottom: 94px;
  }
}